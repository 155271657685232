import useFeatureFlagEnabled from '@/hooks/useFeatureFlagEnabled';
import { lazy } from 'react';
import useAuthentication from '../hooks/useAuthentication';
const PersonasStaffAppPage = lazy(() => import('@/pages/personas-staff-app/PersonasStaffAppPage'));
const BudTenderPage = lazy(() => import('../pages/budTender/BudTenderPage'));

export default function WalletSearchRoutes() {
	const isStaffAppEnabled = useFeatureFlagEnabled('staff_app');
	const Page = isStaffAppEnabled ? PersonasStaffAppPage : BudTenderPage;

	return useAuthentication(Page, !isStaffAppEnabled, { isStaffApp: isStaffAppEnabled });
}
