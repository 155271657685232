import * as Sentry from '@sentry/react';

import { utils } from '../helpers';

export default function SentryStart(): void {
	if (utils.isLocal()) return;
	const environment = utils.isStaging() ? 'staging' : utils.isLocal() ? 'development' : 'production';
	const ignoreErrors = [
		'ResizeObserver loop completed with undelivered notifications.',
		'ResizeObserver loop limit exceeded',
		/^Loading CSS chunk \d+ failed\./,
		'TypeError: Failed to fetch',
		'TypeError: NetworkError when attempting to fetch resource.',
		'ChunkLoadError: Loading chunk',
		'Error: Loading CSS chunk',
		/^TypeError: Load failed$/,
	];

	Sentry.init({
		environment,
		dsn: 'https://4c7efa3723c5e52e6b9d19d1f38f55d5@o1296822.ingest.sentry.io/4506145515175936',
		integrations: [
			new Sentry.BrowserTracing({
				// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
				tracePropagationTargets: ['localhost', window.location.origin, 'https://lab.alpineiq.com', 'https://lab.aiqstaging.net']
			}),
			new Sentry.Replay({
				networkDetailAllowUrls: [
					window.location.origin,
					'https://lab.alpineiq.com',
					'https://lab.aiqstaging.net'
				],
			}),
		],
		// Performance Monitoring
		tracesSampleRate: 1.0, // Capture 100% of the transactions
		// Session Replay
		replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
		replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
		beforeSend(event, _) {
			// This error is caused by a ton of random things including browser extensions (This is generally more of a heads-up to developers rather than a critical error)
			if (event.message && ignoreErrors.includes(event.message)) {
				return null;
			}
			return event;
		},
		ignoreErrors
	});
}
