import useFeatureFlagEnabled from '@/hooks/useFeatureFlagEnabled';
import React, { lazy } from 'react';
import useAuthentication from '../hooks/useAuthentication';
const ReceiptLookup = lazy(() => import('@/pages/receipt-lookup/ReceiptLookup'));
const LegacyReceiptLookup = lazy(() => import('@/pages/receipt-lookup/LegacyReceiptLookup'));

export default function ReceiptLookupRoutes() {
	const isReceiptLookupV2Enabled = useFeatureFlagEnabled('receipt_lookup_v2');
	const Page = isReceiptLookupV2Enabled ? ReceiptLookup : LegacyReceiptLookup;
	return useAuthentication(Page);
}
