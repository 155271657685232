import useAuthentication from '@/hooks/useAuthentication';
import useFeatureFlagEnabled from '@/hooks/useFeatureFlagEnabled';
import { lazy } from 'react';
const Personas = lazy(() => import('@/pages/personas/PersonasPage'));
const LegacyPersonas = lazy(() => import('../pages/legacyPersonas/PersonasPage'));

export default function PersonasRoutes() {
	const isPersonasV3Enabled = useFeatureFlagEnabled('personas_v3');
	const Page = isPersonasV3Enabled ? Personas : LegacyPersonas;
	return useAuthentication(Page);
}
